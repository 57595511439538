@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;

  padding: 40px 160px 200px 160px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    #006d8b 8.33%,
    #003a70 50%,
    #002864 75.52%
  );

  @include lt-md {
    padding: 20px 20px 130px 20px;
  }

  .title {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 0px auto 0px auto;
    padding: 0px 8% 30px 8%;
    width: fit-content;
    border-bottom: solid 5px #0aaedb;

    @include lt-md {
      font-size: 30px;
      padding: 0px 5% 20px 5%;
      width: 70%;
      border-bottom: solid 1px #0aaedb;
    }
  }

  .faculties {
    margin: 100px auto 50px auto;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include lt-md {
      display: none;
    }

    .zoom {
      width: 24%;
    }

    img {
      width: 100%;
      aspect-ratio: 1.05;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .faculties-mobile {
    margin: 100px auto 50px auto;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    display: none;

    @include lt-md {
      display: flex;
      height: 50px;
      margin: 30px auto 100px auto;
    }

    .zoom {
      width: 50%;
    }

    a {
      display: block;
      width: 60%;
      margin: auto;

      @include lt-sm {
        width: 80%;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 1.05;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

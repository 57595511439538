@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px;
  }
}

.container2 {
  padding: 0px 70px;
  @include lt-md {
    padding: 0px;
  }
}

.events {
  width: 100%;
  padding: 50px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .event_container {
    width: 47%;
    padding: 0px 0px 60px 0px;

    @include lt-md {
      width: 100%;
    }
  }
}

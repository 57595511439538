.inscrire {
  background-color: var(--blue2);
  border-radius: 10px;
  padding: 13px 55px;
  outline: none;
  border: none;
  cursor: pointer;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--InscBtn);
}

.inscrire2 {
  border-radius: 5px;
  padding: 20px 60px;
  outline: none;
  border: none;
  cursor: pointer;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--blue2);
  color: var(--InscBtn);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon {
  font-size: 25px;
  // border-top: 3px solid white;
  // border-right: 3px solid white;
  border-radius: 500px;
  color: white;
  font-weight: 800;
  margin: 0px 5px 0px 0px;
}

.turning {
  animation: rotate 0.8s linear infinite;
}

.loading {
  opacity: 0.7;
  cursor: not-allowed;
}

@use "../../assets/scss/media-queries.scss" as *;

.main {
  color: var(--blue1);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin: 0px;
  padding: 0px 0px 0px 15px;
  border-left: solid 5px var(--blue2);

  @include lt-md {
    padding: 0px 0px 0px 10px;
    font-size: 23px;
  }
}

.main2 {
  color: var(--blue1);
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0px;
  padding: 0px 0px 0px 0px;

  @include lt-md {
    padding: 0px 0px 0px 10px;
    font-size: 20px;
  }
}

.arrow {
  margin-right: 2px;
}

@use "../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
}

.container {
  width: 100%;
  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px;
  }
}

.description {
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 200% */

  padding: 40px 0px;

  @include lt-md {
    padding: 20px 0px;
    font-size: 20px;
    line-height: 2;
    text-align: left;
  }
}

.full_img {
  width: 100%;
}

.partners {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}

.partner {
  width: 30%;
  aspect-ratio: 370/320;
  object-fit: cover;
  margin: 20px 0px;
  display: block;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 50px;

  &:hover {
    transform: scale(1.05);
  }
}

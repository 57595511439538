:root {
  --blue1: #002864;
  --blue2: #0aaedb;
  --blue3: #004fc5;
  --white: #ffffff;
  --black: #060606;
  --grey0: #efefef;
  --gradiant: linear-gradient(
    90deg,
    rgba(246, 174, 45, 1) 0%,
    rgba(242, 100, 16, 1) 100%
  );

  // specials
  --InscBtn: #ffffff;
  --Nav1Items: #0aaedb;

  // invariants
  --blueA: #011689;
  --blueB: #4e7bbf;
  --blueC: #006396;
  --blueD: #00366d;
}

.theme-blue {
  --blue1: #002864;
  --blue2: #0aaedb;
  --sub-blue1: #0aaedb90;
  --sub-blue2: #0aaedb80;
  --sub-blue3: #0aaedb60;
  --sub-blue4: #0aaedb40;
  --sub-blue5: #0aaedb20;
  --blue3: #004fc5;
  --white: #ffffff;
  --black: #060606;
  --grey0: #efefef;
  --gradiant: linear-gradient(
    90deg,
    rgba(246, 174, 45, 1) 0%,
    rgba(242, 100, 16, 1) 100%
  );

  // specials
  --InscBtn: #ffffff;
  --Nav1Items: #0aaedb;

  // invariants
  --blueA: #011689;
  --blueB: #4e7bbf;
  --blueC: #006396;
  --blueD: #00366d;
}

.theme-yellow {
  --blue1: #002864;
  --blue2: #f5a706;
  --sub-blue1: #f5a70690;
  --sub-blue2: #f5a70680;
  --sub-blue3: #f5a70660;
  --sub-blue4: #f5a70640;
  --sub-blue5: #f5a70620;
  --blue3: #004fc5;
  --white: #ffffff;
  --black: #060606;
  --grey0: #efefef;
  --gradiant: linear-gradient(
    90deg,
    rgba(246, 174, 45, 1) 0%,
    rgba(242, 100, 16, 1) 100%
  );

  // specials
  --InscBtn: #011689;
  --Nav1Items: #f5a706;

  // invariants
  --blueA: #011689;
  --blueB: #4e7bbf;
  --blueC: #006396;
  --blueD: #00366d;
}

.theme-red {
  --blue1: #002864;
  --blue2: #ab1a2d;
  --sub-blue1: #ab1a2d90;
  --sub-blue2: #ab1a2d80;
  --sub-blue3: #ab1a2d80;
  --sub-blue4: #ab1a2d60;
  --sub-blue5: #ab1a2d20;
  --blue3: #004fc5;
  --white: #ffffff;
  --black: #060606;
  --grey0: #efefef;
  --gradiant: linear-gradient(
    90deg,
    rgba(246, 174, 45, 1) 0%,
    rgba(242, 100, 16, 1) 100%
  );

  // specials
  --InscBtn: #ffffff;
  --Nav1Items: #f5a706;

  // invariants
  --blueA: #011689;
  --blueB: #4e7bbf;
  --blueC: #006396;
  --blueD: #00366d;
}

.theme-pink {
  --blue1: #002864;
  --blue2: #cf378c;
  --sub-blue1: #cf378c90;
  --sub-blue2: #cf378c80;
  --sub-blue3: #cf378c60;
  --sub-blue4: #cf378c40;
  --sub-blue5: #cf378c20;
  --blue3: #004fc5;
  --white: #ffffff;
  --black: #060606;
  --grey0: #efefef;
  --gradiant: linear-gradient(
    90deg,
    rgba(246, 174, 45, 1) 0%,
    rgba(242, 100, 16, 1) 100%
  );

  // specials
  --InscBtn: #ffffff;
  --Nav1Items: #f5a706;

  // invariants
  --blueA: #011689;
  --blueB: #4e7bbf;
  --blueC: #006396;
  --blueD: #00366d;
}

.theme-purple {
  --blue1: #002864;
  --blue2: #6e3699;
  --sub-blue1: #6e369990;
  --sub-blue2: #6e369980;
  --sub-blue3: #6e369960;
  --sub-blue4: #6e369940;
  --sub-blue5: #6e369920;
  --blue3: #004fc5;
  --white: #ffffff;
  --black: #060606;
  --grey0: #efefef;
  --gradiant: linear-gradient(
    90deg,
    rgba(246, 174, 45, 1) 0%,
    rgba(242, 100, 16, 1) 100%
  );

  // specials
  --InscBtn: #ffffff;
  --Nav1Items: #f5a706;

  // invariants
  --blueA: #011689;
  --blueB: #4e7bbf;
  --blueC: #006396;
  --blueD: #00366d;
}

@use "../../../../assets/scss/media-queries.scss" as *;

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-md {
    align-items: start;
    flex-direction: column-reverse;
  }
}

.logo {
  @include lt-xxl {
    height: 65px;
  }

  @include lt-md {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
}

.body {
  margin: 50px 0px 0px 0px;

  @include lt-xxl {
    margin: 20px 0px 0px 0px;
  }

  @include lt-md {
    margin: 25px 0px 0px 0px;
  }
}

.degrees {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  flex-wrap: wrap;
}

.degree {
  width: 45%;
  padding: 40px 0px;

  @include lt-md {
    width: 100%;
    padding: 20px 0px;
  }
}

.button {
  margin: 40px 0px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-xxl {
    margin: 0px 0px 0px 0px;
  }

  @include lt-md {
    button {
      font-size: 16px !important;
      padding: 18px 50px;
    }
  }
}

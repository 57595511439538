@use "../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 40px 300px 40px 250px;
  background-color: var(--black);

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-md {
    padding: 20px 20px;
    flex-direction: column;
  }

  h3 {
    margin: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;

    color: var(--white);

    @include lt-md {
      margin: 0px 0px 20px 0px;
      text-align: center;
      font-size: 22px;
    }
  }

  .icons {
    color: white;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @include lt-md {
      font-size: 35px;
    }

    .icon {
      margin: 0px 20px;
      cursor: pointer;

      @include lt-md {
        margin: 0px 13px;
      }
    }
  }
}

@use "../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 70px 50px 400px 50px;

  @include lt-md {
    padding: 20px 20px 50px 20px;
  }
}

.hexagonals_container {
  padding: 20px 0px 0px 0px;

  @include lt-md {
    padding: 20px 0px 0px 0px;
  }
}

.hexagonals,
.hexagonals_mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px;
}

.hexagonals {
  display: flex;

  @include lt-md {
    display: none;
  }
}

.hexagonals_mobile {
  display: none;

  @include lt-md {
    display: flex;
  }
}

.hexagonal {
  width: 30%;
  aspect-ratio: 1;
  // background-color: #0aaedb94;
  border: solid 3px var(--blue2);
  box-shadow: 0px 0px 10px 0px #00000020;
  border-radius: 10px;
  margin: 10px 1.5%;

  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-md {
    width: 70%;
  }
}

.inside {
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;

  h2 {
    padding: 0px;
    margin: 0px;

    color: var(--blue2);
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @include lt-lg {
      font-size: 24px;
    }

    @include lt-md {
      font-size: 20px;
    }
  }

  p {
    margin: 0px;
    padding: 20% 0px;

    color: #002864;
    text-align: center;
    font-family: Montserrat;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @include lt-lg {
      font-size: 18px;
      padding: 10% 0px;
    }

    @include lt-md {
      padding: 13% 0px;
      font-size: 20px;
      line-height: 1.2;
    }
  }

  button {
    color: var(--blue2);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    background-color: transparent;
    outline: none;
    border: solid 3px var(--blue2);
    padding: 8px 15px;
    border-radius: 500px;
    cursor: pointer;

    @include lt-md {
      font-size: 14px;
      padding: 8px 10px;
    }
  }
}

/* 

.main {
  width: 100%;
  padding: 70px 50px 400px 50px;

  @include lt-md {
    padding: 20px 20px 50px 20px;
  }
}

.hexagonals_container {
  padding: 150px 0px 0px 0px;

  @include lt-md {
    padding: 50px 0px 0px 0px;
  }
}

.hexagonals,
.hexagonals_mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -7% 0px -7% 0px;
}

.hexagonals {
  display: flex;

  @include lt-md {
    display: none;
  }
}

.hexagonals_mobile {
  display: none;

  @include lt-md {
    display: flex;
  }
}

.hexagonal {
  width: 30%;
  aspect-ratio: 0.866;
  // background-color: #0aaedb94;
  margin: 0px 1.5%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url(../../../assets/svgs/hexa.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 115%;

  @include lt-md {
    width: 70%;
  }
}

.inside {
  width: 75%;
  aspect-ratio: 1.4;

  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;

  h2 {
    padding: 0px;
    margin: 0px;

    color: var(--blue2);
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @include lt-md {
      font-size: 20px;
    }
  }

  p {
    margin: 0px;
    padding: 20% 0px;

    color: #002864;
    text-align: center;
    font-family: Montserrat;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @include lt-md {
      padding: 13% 0px;
      font-size: 20px;
      line-height: 1.2;
    }
  }

  button {
    color: var(--blue2);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    background-color: transparent;
    outline: none;
    border: solid 3px var(--blue2);
    padding: 8px 15px;
    border-radius: 500px;
    cursor: pointer;

    @include lt-md {
      font-size: 14px;
      padding: 8px 10px;
    }
  }
}
 

*/

@use "../../assets/scss/media-queries.scss" as *;

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;

  img {
    height: 65px;
    width: 65px;
    margin: 0px 10px 0px 0px;

    @include lt-md {
      height: 50px;
      width: 50px;
      margin: 0px 8px 0px 0px;
    }
  }

  span {
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    text-align: center;

    @include lt-md {
      font-size: 20px;
    }
  }

  .blue1 {
    color: var(--blue1);
  }

  .blue2 {
    color: var(--blue2);
  }

  .blue3 {
    color: var(--blue3);
  }

  .white {
    color: var(--white);
  }

  .grey {
    color: var(--grey);
  }
}

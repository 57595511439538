@use "../../../../assets/scss/media-queries.scss" as *;

.header {
  width: 100%;
  height: 100vh;
  position: relative;

  @include lt-md {
    height: 800px;
  }
}

.teacher {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0px;
}

.swipper-container {
  padding: 0px 0px 50px 0px;
  width: 100%;
  position: absolute;
  z-index: 3;
  bottom: 0px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-md {
    display: none;
  }
}

.title-container {
  width: 100%;
  padding: 40px 0px;
  position: absolute;
  z-index: 5;
  top: 0px;
  margin: auto;

  h2 {
    color: var(--blue2);
  }
}

.content {
  width: 45%;
  height: 80%;

  position: absolute;
  top: 20%;
  right: 0px;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include lt-md {
    width: 100%;
    top: 0%;
    padding: 190px 20px 0px 20px;
    justify-content: flex-start;
  }

  .slogon {
    width: 85%;
    position: relative;

    @include lt-md {
      width: 100%;
    }

    .quote1 {
      width: 230px;
      position: absolute;
      top: -90px;
      left: -110px;

      @include lt-md {
        width: 100px;
        top: -50px;
        left: -20px;
      }
    }

    .quote2 {
      width: 230px;
      position: absolute;
      bottom: -90px;
      right: 50px;

      @include lt-md {
        width: 100px;
        bottom: -50px;
        right: -20px;
      }
    }

    h2 {
      color: #0aaedb;
      text-align: center;
      font-family: Montserrat;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0px;
      padding: 0px 50px 0px 0px;

      @include lt-md {
        font-size: 20px;
        padding: 0px 0px 0px 0px;
      }
    }
  }

  .see-more-container {
    padding: 20px 50px;
  }
}

.teacher-img-mobile {
  display: none;
  height: 300px;
  margin: 50px 0px 20px 0px;
  transform: scale(1);
  @include lt-md {
    display: block;
  }
}

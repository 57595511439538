.header {
  width: 100%;
  height: 100vh;
  position: relative;

  .teacher {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50px;
  }

  .swipper-container {
    width: 100%;
    position: absolute;
    z-index: 3;
    bottom: 0px;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    .swipper {
      width: 300px;
      height: 100px;

      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        display: block;
        margin: 0px 10px;
        height: 20px;
        width: 20px;
        border-radius: 500px;
        background-color: #d9d9d9;
        cursor: pointer;
      }

      .active {
        background-color: var(--blue2);
      }
    }
  }

  .content {
    width: 45%;
    height: 80%;

    position: absolute;
    top: 20%;
    right: 0px;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    .slogon {
      width: 100%;
      position: relative;

      img {
        width: 230px;
        position: absolute;
        top: -90px;
        left: -110px;
      }

      h2 {
        color: #0aaedb;
        text-align: center;
        font-family: Montserrat;
        font-size: 90px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0px;
        padding: 0px;
        max-width: 700px;
      }
    }
  }
}

.body {
  padding: 0px 130px;
  width: 100%;
  background-color: #e1eded;

  .container {
    width: 100%;
    height: 100%;
    background-color: #fefeff;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.2);
    position: relative;

    padding: 70px 130px;
  }

  .title {
    font-weight: 500;
  }
}

.teachers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .teacher {
    width: 18%;
    margin: 20px 0px;
  }

  .transparent {
    opacity: 0;
  }
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 50px 0px 0px 0px;
  width: 100%;

  button {
    color: #fff;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    padding: 20px 80px;
  }
}

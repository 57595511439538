@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px;
  }
}

.container2 {
  padding: 0px 40px;

  @include lt-md {
    padding: 0px;
  }
}

.qa {
  border: solid 1px rgba(0, 0, 0, 0.266);

  margin: 20px 0px;
  border-radius: 5px;

  @include lt-md {
    margin: 15px 0px;
  }

  * {
    transition: all 0.2s;
  }
}

.question {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.266);
  cursor: pointer;

  span {
    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    line-height: 40px;
    font-weight: 500;

    @include lt-md {
      font-size: 18px;
      line-height: 2;
      text-align: left;
    }
  }

  img {
    height: 12px;
    @include lt-md {
      margin: 0px 0px 0px 10px;
    }
  }
}

.answer {
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  line-height: 1.5;
  font-weight: 400;

  height: 0px;
  overflow: hidden;
  padding: 0px 15px;
  border-bottom: solid 0px rgba(0, 0, 0, 0.266);

  @include lt-md {
    text-align: left;
  }
}

.opened {
  .question {
    background-color: #e7f1ff;
    span {
      color: #176de6 !important;
    }

    img {
      transform: scaleY(-1);
    }
  }

  .answer {
    height: fit-content;
    overflow: hidden;
    padding: 10px 15px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.266);
  }
}

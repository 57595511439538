@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;

  padding: 40px 160px 100px 160px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    #006d8b 8.33%,
    #003a70 50%,
    #002864 75.52%
  );

  @include lt-md {
    padding: 20px 20px 50px 20px;
  }

  .title {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    margin: 0px;
    padding: 20px 0px 0px 0px;

    @include lt-md {
      font-size: 28px;
    }
  }
}

.parcour {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px;

  @include lt-md {
    flex-direction: column-reverse;
  }

  .number {
    color: #fff;
    font-family: Montserrat;
    font-size: 150px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
    padding: 0px;
    width: 180px;

    @include lt-md {
      font-size: 80px;
      text-align: center;
    }

    .plus-mob {
      display: none;
      @include lt-md {
        display: inline-block;
      }
    }
  }

  .plus {
    color: #fff;
    font-family: Montserrat;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
    padding: 0px;

    @include lt-md {
      display: none;
    }
  }

  p {
    color: #0aaedb;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 0px 0px 100px -35px;
    padding: 0px;

    @include lt-md {
      font-size: 20px;
      margin: 0px 0px 0px 0px;
      text-align: center;
    }
  }
}

.hr {
  margin: 20px auto !important;
  width: 34%;

  @include lt-md {
    width: 70%;
  }
}

.items_container {
  width: 85%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-md {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include lt-md {
      width: 50%;
      min-width: 50%;
      margin: 20px 0px;
    }

    img {
      height: 80px;

      @include lt-md {
        height: 50px;
      }
    }

    p {
      margin: 0px;
      padding: 0px;

      @include lt-md {
        text-align: center;
      }
    }

    .number {
      color: #fff;
      font-family: Montserrat;
      font-size: 40px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 10px 0px;

      width: 150px;
      text-align: center;

      @include lt-md {
        width: auto;
        font-size: 26px;
      }
    }

    .desc {
      color: #fff;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @include lt-md {
        font-size: 16px;
      }
    }
  }

  .blued {
    .desc {
      color: #6dc4ef;
      font-size: 25px;
      font-weight: 500;

      @include lt-md {
        font-size: 16px;
      }
    }
  }
}

.items_container2 {
  width: 60%;
  margin-top: 30px;

  @include lt-md {
    flex-wrap: wrap;
    width: 100%;
  }
}

.details {
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;

  @include lt-md {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 20px;
  }

  .item2 {
    @include lt-md {
      margin: 0px 0px 20px 0px;
    }

    @include lt-sm {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0px 0px 50px 0px;
      text-align: center;

      br {
        display: none;
      }
    }

    p {
      margin: 0px;
      padding: 0px;
    }

    .number {
      color: #fff;
      font-family: Montserrat;
      font-size: 40px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 5px 0px;

      @include lt-md {
        font-size: 30px;
      }
    }

    .desc {
      color: #6dc4ef;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @include lt-md {
        font-size: 18px;
      }
    }
  }

  .see_more {
    @include lt-md {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }
}

@use "../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  background-color: var(--blue1);
  padding: 100px 120px;

  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include lt-xxl {
    padding: 70px 70px;
  }

  @include lt-md {
    padding: 20px 20px;
    flex-direction: column;
  }
}

.left {
  width: 50%;
  padding: 0px 100px 0px 0px;
  border-right: solid 1px rgba(255, 255, 255, 0.5);

  @include lt-md {
    width: calc(100vw - 50px);
    height: 300px;
    padding: 0px 0px 0px 0px;
    border-right: solid 0px rgba(255, 255, 255, 0.5);
    display: none;
  }
}

.right {
  width: 50%;
  padding: 0px 0px 0px 70px;

  border-left: solid 1px rgba(255, 255, 255, 0.5);

  @include lt-md {
    padding: 0px 0px 0px 0px;
    border-left: solid 0px rgba(255, 255, 255, 0.5);
    width: 100%;
  }

  .logo {
    width: 240px;
    aspect-ratio: 240/140;

    @include lt-md {
      width: 200px;
      display: block;
      margin: 20px auto 0px auto;
    }
  }
}

.flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-xxl {
    flex-direction: column;
    align-items: flex-start;
  }
}

.cont-info {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  margin: 50px 0px;

  @include lt-xxl {
    margin: 30px 0px;
  }

  @include lt-md {
    margin: 15px 0px;
  }

  img {
    margin: 0px 20px 0px 0px;
    height: 100%;

    @include lt-md {
      width: 50px;
    }
  }

  .infos {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    h2 {
      margin: 0px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 30px;

      color: #0aaedb;

      @include lt-xxl {
        font-size: 18px;
      }
    }
  }
}

@use "../../../assets/scss/media-queries.scss" as *;

.actuality {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    margin: 0px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;

    text-align: center;

    @include lt-md {
      font-size: 25px;
      width: 70%;
      line-height: 1.2;
    }
  }
  hr {
    margin: 30px 0px 0px 0px;
    display: block;

    height: 3px;
    width: 540px;

    border: none;
    outline: none;

    @include lt-md {
      margin: 20px 0px 0px 0px;
      width: 70%;
      height: 1px;
    }
  }
}

.blue {
  h2 {
    color: var(--blue3);
  }
  hr {
    background-color: var(--blue3);
  }
}

.white {
  h2 {
    color: var(--white);
  }
  hr {
    background-color: var(--blue2);
  }
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  min-height: 100vh;
  padding: 60px 260px;

  @include lt-md {
    padding: 50px 20px 80px 20px;
  }
}

.events {
  width: 100%;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-md {
    padding: 30px 0px;
    flex-wrap: wrap;
  }
}

.rest-events {
  width: 49%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @include lt-md {
    width: 100%;
    aspect-ratio: unset;
  }
}

.rest-events2 {
  width: 100%;
  height: 48.5%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-md {
    height: fit-content;
    flex-wrap: wrap;
  }
}

.event {
  overflow: hidden;
  position: relative;
  .event-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.01);
  }

  .event-filter {
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(1.01);
    z-index: 1;
    position: absolute;
    top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    transform: scale(1.01);
    z-index: 2;
    position: absolute;
    top: 0;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;

    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      margin: 0px;
      padding: 0px;
      color: var(--white);
    }

    .description {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: var(--white);

        @include lt-md {
          font-size: 16px;
          line-height: 20px;
        }
      }

      img {
        cursor: pointer;
      }
    }
  }
}

.big-event {
  width: 49%;
  aspect-ratio: 1/1;

  @include lt-md {
    width: 100%;
    margin: 10px 0px;
  }

  .content {
    padding: 30px;

    h2 {
      color: var(--white);
      font-size: 35px;
      line-height: 43px;
      margin-bottom: 10px;

      @include lt-md {
        font-size: 26px;
        line-height: 1.2;
      }
    }

    .description {
      img {
        width: 50px;
      }
    }
  }
}

.medium-event {
  width: 100%;
  height: 48.5%;

  @include lt-md {
    height: 50%;
    aspect-ratio: 1.7;
    margin: 10px 0px;
  }

  .content {
    padding: 25px;

    h2 {
      font-size: 30px;
      line-height: 37px;
      margin-bottom: 7px;

      @include lt-md {
        font-size: 22px;
        line-height: 1.2;
      }
    }

    .description {
      img {
        width: 40px;
      }
    }
  }
}

.small-event {
  width: 48%;
  aspect-ratio: 1/1;

  @include lt-md {
    width: 100%;
    aspect-ratio: 1.7;
    margin: 10px 0px;
  }

  .content {
    padding: 20px;

    h2 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 5px;

      @include lt-md {
        font-size: 16px;
      }
    }

    .description {
      img {
        width: 30px;
      }
    }
  }
}

.see-more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  height: fit-content;
  width: 100%;
  background-color: #020a35;
  position: relative;
}

.images {
  width: 100%;
  padding: 5px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @include lt-xxl {
    padding: 0px 0px;
  }
}

.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  .circle {
    position: relative;
    width: 880px;
    aspect-ratio: 880/836;

    @include lt-xxl {
      width: 680px;
    }

    @include lt-md {
      width: 80%;
    }

    .elipse {
      width: 100%;
      height: 100%;
    }

    .sub-content {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 300px;
        aspect-ratio: 300/200;
        background: #002864d7;
        mix-blend-mode: hard-light;
        border-radius: 50px;

        @include lt-md {
          width: 80%;
        }
      }

      .selected {
        width: 650px;
        background: #00286400;
        mix-blend-mode: normal;
      }
    }

    .sub-content2 {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      .container {
        position: relative;
        .left {
          position: absolute;
          left: -100px;
          top: -80px;
          transform: rotate(180deg) scale(1.2);

          @include lt-xxl {
            left: -40px;
            width: 170px;
          }

          @include lt-md {
            width: 50%;
            left: -20%;
            top: -80%;
          }
        }

        h2 {
          margin: 0px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 100px;
          line-height: 122px;
          text-align: center;

          color: var(--white);

          @include lt-md {
            font-size: 40px;
            line-height: 1;
          }
        }

        .right {
          position: absolute;
          right: -100px;
          bottom: -80px;
          transform: rotate(180deg) scale(1.2);

          @include lt-xxl {
            right: -40px;
            width: 170px;
          }

          @include lt-md {
            width: 50%;
            right: -20%;
            bottom: -80%;
          }
        }
      }
    }
  }
}

.fake-images {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  position: absolute;
  top: 0px;
}

.image {
  width: 24.7%;
  margin: 5px 0px;
  aspect-ratio: 315/215;
  cursor: pointer;
  position: relative;

  @include lt-md {
    width: 50%;
    margin: 0px 0px;
  }

  .real-img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  .filter1 {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;

    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0.3;
  }

  .filter2 {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;

    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0.3;
  }
}

.fake-image {
  width: 24.7%;
  aspect-ratio: 315/215;
  cursor: pointer;
  position: relative;

  @include lt-md {
    width: 50%;
  }
}

@use "../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 40px 180px;
  position: absolute;
  z-index: 10;
  top: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: all 0.2s;

  @include lt-md {
    height: 100vh;
    width: 80vw;
    padding: 8% 0px 0% 50px;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    position: fixed !important;
    top: 0px;
    background-color: #002864;
    box-shadow: 0px 0px 10px 2px rgba($color: #000000, $alpha: 0.2);
    transform: translateX(-100%);
    top: 0px;
    left: 0px;
  }

  img {
    width: 140px;
    cursor: pointer;

    @include lt-md {
      width: auto;
      height: 12vh;
      margin: 0vh 0px 4vh 0px;
    }
  }

  // .arrow {
  //   display: none;
  //   width: 50px;

  //   @include lt-md {
  //     display: block;
  //     margin: 0px auto;
  //     transform: translateX(-7vw) rotate(180deg);
  //   }
  // }

  .more {
    display: none;
    width: 100%;
    cursor: pointer;

    align-items: center;
    // justify-content: center;

    @include lt-md {
      display: flex;
      margin: 0px auto;
      // transform: translateX(-7vw);
    }

    img {
      width: 20px;
      margin: 0px;
    }

    span {
      margin: 0px 0px 0px 10px;
      color: white;
      font-size: 20px;
      font-weight: 600;
    }
  }

  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: var(--white);
    cursor: pointer;

    @include lt-md {
      display: block;
      margin: 3vh 0px 3vh 0px;
      font-size: 19px;
      text-align: left;
      color: #0aa8d6;
      font-weight: 600;
    }
  }

  .btn {
    @include lt-md {
      margin: 3vh 0px 3vh 0px;
    }
  }
}

.berger {
  width: 100vw;
  height: 60px;
  padding: 15px;
  background-color: #002864;
  cursor: pointer;

  display: none;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  top: 0px;
  right: -100vw;

  // border: solid rgba(0, 0, 0, 0.523) 0.2px;
  box-shadow: 0px 0px 10px 2px rgba($color: #000000, $alpha: 0.2);

  .icon {
    color: white;
    font-size: 30px;
  }

  img {
    height: 130%;
    margin: 0px;
  }

  @include lt-md {
    display: flex;
  }
}

.close {
  display: none;
  color: white;
  position: absolute;
  right: 30px;
  top: 30px;
  height: 40px;
  width: 40px;
  cursor: pointer;

  @include lt-md {
    display: block;
  }
}

.opened {
  transform: translateX(0%);
  z-index: 99999;
}

.coched {
  background: #00122e8b;
  padding: 10px 180px;

  @include lt-md {
    background: #002864;
    padding: 8% 0px 0% 50px;
  }
}

.coched2 {
  background: #0671a6;
  padding: 10px 180px;

  @include lt-md {
    background: #002864;
    padding: 8% 0px 0% 50px;
  }
}

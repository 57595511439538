@use "../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
}

.container {
  width: 100%;
  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px;
  }
}

.container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  background-color: #e0edec;
  position: relative;
}

.body {
  width: 80%;
  padding: 0px;

  @include lt-md {
    width: 100%;
    padding: 0px;
  }

  .page {
    width: 100%;

    background-color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
}

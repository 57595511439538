@use "../../assets/scss/media-queries.scss" as *;

.main {
  margin: 0px;
  padding: 20px 0px 20px 30px;

  border-left: solid 3px var(--blue2);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.3;

  color: var(--blueA);

  @include lt-xxl {
    font-size: 28px !important;
    padding: 12px 0px 12px 20px;
  }

  @include lt-md {
    padding: 8px 0px 8px 10px;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
}

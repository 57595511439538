.main {
  margin: 0px;
  padding: 0px 0px 0px 5px;
  border-left: solid 2px var(--blueB);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 25px;

  color: var(--blueB);
  cursor: pointer;
}

.active {
  border-left: solid 6px var(--blue2);
  color: var(--blueA);
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px;
  }
}

.container2 {
  padding: 0px 40px;

  @include lt-md {
    padding: 0px 10px;
  }
}

.desc {
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  line-height: 40px;
  font-weight: 500;

  @include lt-md {
    font-weight: 400;
  }
}

.btn {
  margin: 50px auto 0px auto;
  display: block;
}

.carossel {
  width: 100%;
  margin: 50px 0px -50px 0px;
}

.item {
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include lt-md {
    flex-wrap: wrap;
  }

  .general {
    width: 33%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    @include lt-md {
      width: 100%;
    }

    h2 {
      color: #002864;
      text-align: center;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      margin: 10px 0px 0px 0px;
      padding: 0px;

      @include lt-md {
        font-size: 24px;
        line-height: 1.5;
        margin: 0px 0px 0px 0px;
      }
    }
  }

  .description {
    width: 62%;

    @include lt-md {
      width: 100%;
    }
  }
}

.hexagonal {
  width: 70%;
  aspect-ratio: 0.866;
  background-color: #0aaedb94;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0);
  margin: 0px 1.5%;

  display: flex;
  align-items: center;
  justify-content: center;

  .sub_hexa {
    width: 96%;
    aspect-ratio: 0.866;
    background-color: white;
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0);
    margin: 0px 1.5%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .for_shadow {
    width: 98%;
    height: 98%;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub_sub_hexa {
    width: 95%;
    aspect-ratio: 0.866;
    background-color: white;
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0);
    margin: 0px 1.5%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.img_container {
  width: 90%;
  aspect-ratio: 1/1;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px;
  }
}

.container2 {
  padding: 0px 70px;

  @include lt-md {
    padding: 0px;
  }
}

.medias {
  width: 100%;
  padding: 50px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @include lt-md {
    padding: 30px 0px 0px 0px;
    justify-content: center;
  }

  .media {
    width: 31%;
    aspect-ratio: 3/2;
    padding: 0px 0px 30px 0px;

    @include lt-md {
      width: 49%;
      padding: 0px 0px 2px 0px;
      margin: auto;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      cursor: pointer;
    }
  }
}

.full_img {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.8);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;

  .layer1 {
    width: 70%;
    transform: translateY(-30px);

    @include lt-md {
      width: 90%;
    }

    .back {
      width: 50px;
      cursor: pointer;
      margin: 20px 0px 20px 55px;

      @include lt-md {
        width: 40px;
        margin: 20px auto 20px auto;
      }
    }

    .images {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      .left {
        height: 50px;
        cursor: pointer;
        margin: 0px 20px;

        @include lt-md {
          width: 30px;
          position: absolute;
          bottom: -70px;
          left: -15px;
        }
      }

      .right {
        height: 50px;
        cursor: pointer;
        transform: scaleX(-1);
        margin: 0px 20px;

        @include lt-md {
          width: 30px;
          position: absolute;
          bottom: -70px;
          right: -15px;
        }
      }

      .puces {
        display: none;
        position: absolute;
        bottom: -50px;

        @include lt-md {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .puce {
          width: 10px;
          height: 10px;
          background-color: white;
          margin: 0px 5px;
          border-radius: 500px;
        }

        .active {
          width: 14px;
          height: 14px;
          background-color: var(--blue2);
          margin: auto;
          border-radius: 500px;
        }
      }

      .main_img {
        height: 80vh;

        @include lt-md {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@use "../../../../assets/scss/media-queries.scss" as *;

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-md {
    align-items: start;
    flex-direction: column-reverse;
  }
}

.logo {
  @include lt-xxl {
    height: 65px;
  }

  @include lt-md {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
}

.body {
  margin: 50px 0px 0px 0px;

  @include lt-md {
    margin: 25px 0px 0px 0px;
  }
}

.button {
  margin: 40px 0px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-md {
    button {
      font-size: 16px !important;
      padding: 18px 50px;
    }
  }
}

.books {
  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-md {
    flex-wrap: wrap;
    align-items: stretch;
  }
}

.book {
  width: 20%;
  margin: 0px 2%;
  cursor: pointer;

  @include lt-md {
    width: 44%;
    margin: 0px 2%;
  }

  img {
    width: 100%;
  }

  h3 {
    text-align: center;

    font-style: normal;
    font-weight: 600;
    font-size: 19px;

    @include lt-xxl {
      font-size: 16px;
    }
  }
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px 20px 20px 20px;
  }
}

.date {
  color: #002864;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 10px;

  @include lt-md {
    font-size: 22px;
  }
}

.container2 {
  padding: 0px 70px;

  @include lt-md {
    padding: 0px 10px;
  }
}

.events {
  width: 100%;
  padding: 50px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .event_container {
    width: 47%;
    padding: 0px 0px 60px 0px;

    @include lt-md {
      width: 100%;
    }
  }
}

.main_img {
  width: 100%;
}

.parag {
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  padding: 0px 0px;
}

.images {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  div {
    width: 24%;
    margin: 0.5%;
    cursor: pointer;

    @include lt-md {
      width: 49%;
    }
  }

  img {
    width: 100%;
  }
}

@use "../../../assets/scss/media-queries.scss" as *;

.card_container {
  padding: 5px 0px 0px 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include lt-xxl {
    padding: 10px 0px 10px 0px;
  }
}

.card_p {
  font-size: 20px !important;

  @include lt-xxl {
    font-size: 17px !important;
  }
}

.blue_text {
  color: var(--blue2) !important;
  padding-left: 4px;
}

.sub_title {
  font-size: 25px;
  font-weight: 600;
  font-family: "Montserrat";
  color: var(--blue2);
  padding: 10px 0px 10px 0px;
  margin: 0px;

  @include lt-xxl {
    font-size: 20px;
  }
}

.topImage {
  width: 80%;
  display: block;
  margin: 20px auto 0px auto;
  object-fit: cover;

  @include lt-xxl {
    width: 100%;
  }
}

.botImage {
  width: 60%;
  display: block;
  margin: 20px auto 0px auto;
  object-fit: cover;

  @include lt-xxl {
    width: 70%;
  }

  @include lt-md {
    width: 80%;
  }

  @include lt-sm {
    width: 100%;
  }
}

.button {
  margin: 40px 0px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-md {
    button {
      font-size: 16px !important;
      padding: 18px 50px;
    }
  }
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  height: 100vh;

  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  width: 60%;
  height: fit-content;
  background-color: white;
  padding: 50px;

  position: relative;

  @include lt-md {
    width: 95%;
    padding: 50px 25px;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;

  @include lt-md {
    top: 20px;
    right: 20px;
  }

  .close_icon {
    font-size: 35px;
    cursor: pointer;

    @include lt-md {
      font-size: 30px;
    }
  }
}

.form {
  width: 100%;
  padding: 20px 0px 0px 0px;
  margin: auto;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  .w100 {
    width: 100%;
    margin: 0px 0px 15px 0px;
  }

  .w50 {
    width: 48%;
    margin: 0px 0px 15px 0px;
  }

  label {
    display: block;
    width: 100%;

    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 2; /* 200% */

    @include lt-md {
      font-size: 18px;
    }
  }

  select {
    display: block;
    width: 100%;
    border: solid 1px #000;

    padding: 20px 20px;

    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 200% */

    @include lt-md {
      font-size: 16px;
      padding: 2px 12px;
    }
  }

  input,
  textarea {
    display: block;
    width: 100%;
    border: solid 1px #000;

    padding: 6px 20px;

    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 200% */

    @include lt-md {
      font-size: 16px;
      padding: 2px 12px;
    }
  }
}

.send {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 20px 0px;

  button {
    padding: 20px 100px;
    width: 100%;
    font-size: 22px;

    border-radius: 0px;

    @include lt-md {
      font-size: 20px;
      padding: 18px 100px;
    }
  }
}

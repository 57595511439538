.langSelect {
  * {
    color: white;
    border: none !important;
  }

  border: none !important;

  ul {
    * {
      color: black;
    }
  }
}

.isUp {
  ul {
    top: -110px !important;
  }
}

@use "../../../assets/scss/media-queries.scss" as *;

.main {
  padding: 0px 130px;
  width: 100%;
  background-color: #e1eded;

  @include lt-xxl {
    padding: 0px 90px;
  }

  @include lt-md {
    padding: 0px 0px;
  }
}

.container {
  width: 100%;
  height: 100%;
  background-color: #fefeff;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.2);
  position: relative;

  padding: 70px 130px;

  @include lt-xxl {
    padding: 70px 100px;
  }

  @include lt-md {
    padding: 30px 20px;
  }
}

.options {
  height: 100px;
  position: absolute;
  top: -100px;
  left: 130px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include lt-xxl {
    height: 90px;
    top: -90px;
  }

  @include lt-md {
    left: 0px;
    width: 100%;
    height: 70px;
    top: -70px;
  }
}

.option {
  height: 100%;
  width: 200px;
  background: #eeeeee;
  opacity: 0.9;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #737272;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  z-index: 5;

  @include lt-xxl {
    font-size: 22px;
    width: 180px;
  }

  @include lt-md {
    // width: 25%;
    flex-grow: 1;
    font-size: 14px;
  }
}

.with-icon {
  background: var(--blue2);
  opacity: 1;

  color: white;

  img {
    @include lt-xxl {
      width: 32px;
    }

    @include lt-md {
      width: 22px;
    }
  }
}

.selected {
  background: #ffffff;

  opacity: 1;
  font-weight: 600;

  color: var(--blueA);
}

.block {
  width: 8px;
  height: 27px;
  margin: 0px 10px 0px 0px;
  background: var(--blue2);

  @include lt-xxl {
    width: 6px;
  }

  @include lt-md {
    width: 5px;
    margin: 0px 6px 0px 0px;
    height: 25px;
  }
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  height: 100vh;

  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 1);
}

.filter2 {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1;
  top: 0;

  // background: linear-gradient(to bottom, rgba(#109374, 1), rgba(#109374, 0));
  mix-blend-mode: hard-light;
  opacity: 0.4;
}

.video-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  .video {
    width: 100%;

    @include lt-md {
      height: 100%;
      width: auto;
    }
  }
}

.filter1 {
  width: 100%;
  height: 100vh;

  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 1;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .left {
    width: 53%;
  }

  .right {
    width: 47%;
    position: relative;
    img {
      width: 200px;
    }

    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 150px;
      line-height: 183px;
      text-align: left;
      color: var(--white);

      margin: 0px;
      padding: 0px;

      position: absolute;
      top: 50px;
      left: 140px;
    }
  }
}

.scroll-indicator {
  display: flex;
  width: 100%;
  height: 100vh;

  position: absolute;
  z-index: 2;
  top: 0;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include lt-md {
    display: flex;
  }

  .logo {
    width: 20%;
    margin: 0px auto 0vh auto;

    @include lt-md {
      width: 50%;
    }
  }
}

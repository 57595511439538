@use "../../assets/scss/media-queries.scss" as *;

.hr {
  margin: 50px auto 30px auto;
  background-color: var(--blue2);
  width: 25%;
  height: 1px;
  outline: none;
  border: none;

  @include lt-md {
    width: 75%;
  }
}

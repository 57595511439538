.swipper-container {
  width: 100%;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  .swipper {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      display: block;
      margin: 0px 10px;
      height: 20px;
      width: 20px;
      border-radius: 500px;
      background-color: #d9d9d9;
      cursor: pointer;
    }

    .active {
      background-color: var(--blue2);
    }
  }
}

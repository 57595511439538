@use "../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  aspect-ratio: 248.938/373.406;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;

    @include lt-md {
      border-radius: 15px;
    }
  }

  h2 {
    position: absolute;
    bottom: 10%;
    text-align: center;
    z-index: 5;
    width: 100%;
    margin: 0px;
    padding: 0px;

    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @include lt-xxl {
      font-size: 20px;
    }

    @include lt-md {
      font-size: 16px;
    }
  }
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #0364bb 18.75%,
    #023668 56.25%,
    #021d3b 77.08%
  );
  padding: 80px 0px;

  @include lt-md {
    padding: 40px 0px;
  }
}

.students {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  @include lt-md {
    flex-direction: column;
  }
}

.student {
  min-width: 24%;
  margin: 70px 20px 0px 20px;
  aspect-ratio: 400/717;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  position: relative;

  @include lt-md {
    min-width: 90%;
    margin: 40px 20px 0px 20px;
  }

  .image {
    height: 100%;
    width: 100%;
    // object-fit: cover;

    position: absolute;
  }

  .filter {
    opacity: 0.3;
    width: 100%;
  }
}

.hidden {
  min-width: 22%;
}

.content {
  padding: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  width: 100%;
  height: 100%;
  position: absolute;

  h3 {
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: var(--white);
  }

  img {
    cursor: pointer;
  }
}

.is_playing {
  opacity: 0;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

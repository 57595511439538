@use "../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  aspect-ratio: 3/2;
  position: relative;
  cursor: pointer;
}

.img {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.data {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-image: url(../../assets/images/filter_event2.png);
  background-size: 100%;

  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;

  h2 {
    margin: 0px 0px 10px 0px;
    color: #fff;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @include lt-md {
      font-size: 22px;
    }
  }

  p {
    margin: 0px;
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @include lt-md {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

@use "../../../assets/scss/media-queries.scss" as *;

.main {
  background-color: #002864;
  height: 50px;
  width: 100%;
  padding: 0px 180px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  z-index: 20;
  transition: all 0.2s;

  @include lt-md {
    height: 100vh;
    width: 80vw;
    padding: 8% 0px 0px 50px;
    flex-direction: column;
    position: fixed !important;
    transform: translateX(-100%);
    box-shadow: 0px 0px 10px 2px rgba($color: #000000, $alpha: 0.2);
    top: 0px;
    left: 0px;
    z-index: 9999;
  }

  img {
    cursor: pointer;
  }

  .berger {
    width: 100vw;
    height: 60px;
    padding: 15px;
    background-color: #002864;
    cursor: pointer;

    display: none;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    top: 0px;
    right: -100vw;

    // border: solid rgba(0, 0, 0, 0.523) 0.2px;
    box-shadow: 0px 0px 10px 2px rgba($color: #000000, $alpha: 0.2);

    .icon {
      color: white;
      font-size: 30px;
    }

    img {
      height: 130%;
    }

    @include lt-md {
      display: flex;
    }
  }
}

.opened {
  transform: translateX(0%);
  z-index: 99999;

  .berger {
    transform: translateX(-80%);
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include lt-md {
    height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .close {
    display: none;
    color: white;
    position: absolute;
    right: 30px;
    top: 30px;
    height: 40px;
    width: 40px;
    cursor: pointer;

    @include lt-md {
      display: block;
    }
  }

  img {
    display: none;
    height: 12%;

    @include lt-md {
      height: 12vh;
      display: block;
    }
  }

  span {
    margin: 0px 0px;

    @include lt-md {
      margin: 13% 0px 0px 0px;
      font-size: 19px;
    }
  }
}

.more {
  display: none;
  width: 100%;
  cursor: pointer;

  align-items: center;
  justify-content: flex-start;

  @include lt-md {
    display: flex;
    margin: 0px auto;
  }

  img {
    width: 20px;
    transform: scaleX(-1);
  }

  span {
    margin: 0px 10px 0px 0px;
    color: white;
    font-size: 20px;
    font-weight: 600;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 20px;
    height: 18px;
    margin: 0px 10px;
  }
}

.link {
  color: var(--Nav1Items);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  display: block;
  cursor: pointer;

  a {
    color: #0aa8d6;
  }
}

.btn {
  display: none !important;
  @include lt-md {
    display: block !important;
    margin: 13% 0px 0vh 0px;
  }
}

.lang {
  display: block !important;
  @include lt-md {
    display: none !important;
  }
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Montserrat";
    font-style: normal;
    line-height: 1.5;
    text-align: justify;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 28px;
    margin: 20px 0px;

    @include lt-xxl {
      font-size: 24px;
    }

    @include lt-md {
      margin: 20px 0px 0px 0px;
      font-size: 18px;
    }
  }

  p {
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 2;
    /* or 162% */

    text-align: justify;

    color: #000000;

    @include lt-xxl {
      font-size: 22px;
    }

    @include lt-md {
      font-size: 18px;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-md {
    align-items: start;
    flex-direction: column-reverse;
  }
}

.logo {
  @include lt-xxl {
    height: 65px;
  }

  @include lt-md {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
}

.body {
  margin: 30px 0px 0px 0px;

  @include lt-md {
    margin: 25px 0px 0px 0px;
  }
}

.setion1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .student {
    width: 39%;
  }

  .p {
    width: 100%;
  }
}

.h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 30px;
  line-height: 1.5;
  /* or 162% */

  text-align: justify;

  @include lt-xxl {
    font-size: 24px;
  }

  color: #000000;

  @include lt-md {
    font-size: 18px;
  }
}

.p {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.5;
  /* or 162% */

  text-align: justify;

  color: #000000;

  @include lt-xxl {
    font-size: 24px;
  }

  @include lt-md {
    font-size: 18px;
    font-weight: 400;
  }
}

.elements {
  padding: 0px 0px 60px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @include lt-xxl {
    padding: 30px 0px 30px 0px;
  }

  @include lt-md {
    padding: 0px 0px 0px 0px;
  }

  span {
    color: var(--blue2);
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    background-color: var(--sub-blue5);
    opacity: 0.9;
    display: block;
    margin: 20px 20px 0px 0px;
    padding: 20px 25px;

    @include lt-xxl {
      font-size: 20px;
    }

    @include lt-md {
      font-size: 16px;
      padding: 15px 20px;
      margin: 10px 10px;
    }
  }
}

.setion2 {
  margin: 50px 0px 0px 0px;

  @include lt-xxl {
    margin: 40px 0px 0px 0px;
  }

  @include lt-md {
    margin: 30px 0px 0px 0px;
  }
}

.button {
  margin: 40px 0px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-md {
    button {
      font-size: 16px !important;
      padding: 18px 50px;
    }
  }
}

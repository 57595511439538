@use "../../assets/scss/media-queries.scss" as *;

.main {
  background: linear-gradient(180deg, #0aafda 0%, #4bd1f5 100%);
  padding: 30px 0px 30px 160px;
  margin-top: -1px;

  @include lt-md {
    padding: 15px 0px 15px 20px;
  }

  h1 {
    color: #fff;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0px 0px 0px 15px;
    border-left: solid 5px white;
    margin: 0px;

    @include lt-xxl {
      font-size: 26px;
      padding: 0px 0px 0px 10px;
    }

    @include lt-md {
      font-size: 26px;
      padding: 0px 0px 0px 10px;
    }
  }
}

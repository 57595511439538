@use "../../assets/scss/media-queries.scss" as *;

.main {
  padding: 0px 130px;
  width: 100%;
  background-color: #e1eded;

  @include lt-xxl {
    padding: 0px 100px;
  }

  @include lt-md {
    padding: 0px 0px;
  }

  .container {
    width: 100%;
    background-color: #fefeff;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.2);
    position: relative;
    padding: 250px 130px 0px 130px;

    @include lt-xxl {
      padding: 200px 80px 80px 80px;
    }

    @include lt-md {
      padding: 100px 20px 0px 20px;
    }
  }
}

.teacher {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @include lt-md {
    flex-direction: column-reverse;
  }

  .user-img,
  .user-img-mobile {
    border-radius: 41px;
    overflow: hidden;
    width: 40%;

    margin: 0px 0px 0px 0px;
    display: block;

    img {
      width: 100%;
    }

    @include lt-xxl {
      width: 35%;
    }

    @include lt-md {
      margin: 30px auto;
      width: 75%;
    }
  }

  .user-img {
    display: block;

    @include lt-md {
      display: none;
    }
  }

  .user-img-mobile {
    display: none;

    @include lt-md {
      display: block;
    }
  }

  .name {
    padding: 20px 0px 0px 0px;
    margin: 0px 50px 0px 0px;
    width: 60%;

    @include lt-xxl {
      width: 65%;
    }

    @include lt-md {
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 0px 0px;
      width: 100%;
    }

    .teacher-name {
      color: var(--blue1);
      font-family: Montserrat;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 0px 0px 0px 20px;
      margin: 0px 0px 5px 0px;
      display: block;
      border-left: solid 6px var(--blue2);
      text-align: left;

      @include lt-xxl {
        font-size: 35px;
        padding: 0px 0px 0px 20px;
        margin: 0px 0px 0px 0px;
        text-align: left;
      }

      @include lt-md {
        font-size: 35px;
        padding: 0px 0px 0px 10px;
      }
    }

    .teacher-name-2 {
      color: #00a0e0;
      font-family: Montserrat;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: block;
      padding: 0px 0px 0px 28px;
      margin: 0px 0px 50px 0px;

      @include lt-xxl {
        padding: 0px 0px 0px 28px;
        margin: 0px 0px 40px 0px;
        font-size: 18px;
      }

      @include lt-md {
        font-size: 22px;
        padding: 0px 0px 0px 18px;
      }
    }

    p {
      color: #000;
      text-align: justify;
      font-family: Montserrat;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0px 0px 0px 28px;
      margin: 20px 0px;

      @include lt-xxl {
        font-size: 20px;
      }

      @include lt-md {
        padding: 0px 0px 0px 0px;
        font-size: 18px;
        line-height: 1.5;
      }
    }
  }
}

.other-teachers {
  padding: 100px 0px 30px 0px;

  @include lt-xxl {
    padding: 50px 0px;
  }

  @include lt-md {
    padding: 20px 0px;
  }

  hr {
    width: 60%;
    height: 2px;
    border: none;
    outline: none;
    background: #0aaedb;

    @include lt-md {
      width: 80%;
    }
  }

  .teachers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 4%;
    cursor: pointer;

    @include lt-md {
      padding: 0px 0%;
    }

    .teacher {
      width: 23%;
      margin: 100px 0px 20px 0px;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.05);
      }

      @include lt-xxl {
        margin: 50px 0px 20px 0px;
      }

      @include lt-md {
        width: 46%;
        margin: 2%;
      }
    }

    .transparent {
      opacity: 0;
    }
  }

  .swipper-container {
    width: 100%;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    .swipper {
      width: 300px;
      height: 100px;

      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        display: block;
        margin: 0px 10px;
        height: 20px;
        width: 20px;
        border-radius: 500px;
        background-color: #d9d9d9;
        cursor: pointer;
      }

      .active {
        background-color: var(--blue2);
      }
    }
  }
}

.title {
  display: block;

  color: #002864;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding: 0px 0px 0px 10px;
  border-left: solid 6px var(--blue2);
  margin: 20px 0px 20px 0px;

  @include lt-xxl {
    font-size: 26px;
    margin: 30px 0px;
  }

  @include lt-md {
    font-size: 24px;
    margin: 20px 0px 20px 0px;
  }
}

.Biographie {
  padding: 50px 0px 20px 0px;

  @include lt-xxl {
    padding: 30px 0px 10px 0px;
  }

  @include lt-md {
    padding: 0px 0px 0px 0px;
  }

  p {
    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 0px 0px 20px;

    @include lt-xxl {
      font-size: 22px;
    }

    @include lt-md {
      padding: 0px 0px 0px 0px;
      font-size: 20px;
      line-height: 1.5;
    }
  }
}

.Qualifications {
  padding: 0px 0px 0px 0px;

  @include lt-xxl {
    padding: 0px 0px 0px 0px;
  }

  @include lt-md {
    padding: 0px 0px 0px 0px;
  }

  .elements {
    padding: 0px 0px 0px 20px;

    @include lt-md {
      padding: 0px;
    }

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    span {
      color: #0aaedb;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      background-color: #0aaedb1a;

      display: block;
      margin: 0px 20px 20px 0px;
      padding: 20px 25px;

      @include lt-xxl {
        font-size: 18px;
        padding: 16px 20px;
      }

      @include lt-md {
        margin: 0px 10px 10px 0px;
        font-size: 14px;
        padding: 12px 16px;
      }
    }

    ul {
      padding: 0px 0px 0px 20px;
      margin: 0px;
    }

    li {
      color: #000;
      text-align: justify;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 15px 0px;

      @include lt-xxl {
        font-size: 18px;
      }

      @include lt-md {
        padding: 0px 0px 0px 0px;
        font-size: 18px;
        line-height: 1.5;
      }
    }
  }
}

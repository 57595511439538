@use "../../../assets/scss/media-queries.scss" as *;

.main {
  padding: 0px 0px 50px 0px;
  height: 100%;
  border-bottom: solid 2px var(--blue2);
  position: relative;

  @include lt-md {
    padding: 0px 0px 30px 0px;
    border-bottom: solid 1px var(--blue2);
  }

  h2 {
    margin: 0px;
    padding: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.3;

    color: var(--blueA);
    @include lt-md {
      font-size: 24px;
      line-height: 1;
    }
  }

  p {
    margin: 20px 0px 40px 0px;
    padding: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    text-align: justify;

    color: #000000;

    @include lt-md {
      font-size: 18px;
      margin: 10px 0px 40px 0px;
    }
  }

  button {
    position: absolute;
    bottom: 15px;
    right: 0px;

    padding: 10px 40px;
    cursor: pointer;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;

    color: var(--blue2);
    background-color: white;
    outline: none;
    border: solid 2px var(--blue2);
    border-radius: 5px;

    @include lt-md {
      font-size: 18px;
      padding: 2px 30px;
      border: solid 1px var(--blue2);
    }
  }
}

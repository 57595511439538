@use "../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px;
  }
}

.h2 {
  margin: 0px 0px;
  padding: 0px 0px;
  color: var(--blue2);
  text-align: justify;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;

  @include lt-md {
    font-size: 22px;
    text-align: left;
    line-height: 1.3;
  }
}

.h3 {
  margin: 0px 0px;
  padding: 0px 0px;
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 200% */

  @include lt-md {
    font-size: 22px;
    text-align: left;
    line-height: 1.3;
    margin: 20px 0px 0px 0px;
  }
}

.description {
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 200% */

  padding: 0px 0px;

  @include lt-md {
    font-size: 20px;
    text-align: left;
    line-height: 1.5;
  }
}

.full_img {
  width: 100%;

  margin: 40px 0px;

  @include lt-md {
    margin: 20px 0px;
  }
}

.main table {
  width: 100%;
  border-spacing: 0px;
  margin: 40px 0px 60px 0px;

  @include lt-md {
    margin: 20px 0px 20px 0px;
  }

  td {
    padding: 20px 10px;
    font-size: 20px;
    width: 50%;

    @include lt-md {
      font-size: 18px;
      padding: 15px 10px;
    }
  }

  tr:nth-child(odd) {
    background-color: #f3f3f3;
  }

  th {
    background-color: var(--blue2);
    color: white;
    text-align: left;
  }
}

.ul {
  li {
    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 200% */

    padding: 0px 0px;

    @include lt-md {
      font-size: 20px;
      text-align: left;
      line-height: 1.5;
    }
  }
}

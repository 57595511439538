@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;

  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px;
  }
}

.container2 {
  padding: 0px 40px;

  @include lt-md {
    padding: 0px;
  }
}

.desc {
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include lt-md {
    padding: 0px;
    text-align: left;
  }
}

.parag {
  line-height: 40px;
  font-weight: 500;

  @include lt-md {
    font-weight: 400;
  }
}

.faculties {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;

  @include lt-md {
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    margin: 0px 0px;
  }

  img {
    height: 80px;
    margin: 0px 30px;

    @include lt-xxl {
      height: 60px;
    }

    @include lt-md {
      height: 60px;
      margin: 10px 0px 10px 30px;
    }
  }
}

.desc_item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0px;

  @include lt-md {
    align-items: center;
    flex-direction: column;
  }

  .hexagone {
    width: 220px;
    flex: 0 0 auto;
    aspect-ratio: 0.866;
    margin: 0px 1.5%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-image: url(../../../../assets/svgs/hexa.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 115%;
  }

  .inside {
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include lt-md {
      margin: auto;
      height: 100%;
    }

    img {
      height: 80px;
    }

    h2 {
      margin: 0px;
      padding: 10px;

      color: #002864;
      text-align: center;
      font-family: Montserrat;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  p {
    color: #002864;
    text-align: justify;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; /* 152.174% */
    padding: 30px 0px 0px 0px;

    @include lt-md {
      font-size: 20px;
      line-height: 1.7;
      padding: 0px 0px 0px 0px;
      font-weight: 400;
      text-align: center;
    }
  }
}

.sub_title {
  color: #002864;
  text-align: justify;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin: 40px 0px;
  padding: 0px 0px 0px 10px;
  border-left: solid 6px var(--blue2);

  @include lt-md {
    font-size: 20px;
  }
}

.fac_img {
  width: 100%;
}

@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;

  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 20px;
  }
}

.container2 {
  padding: 0px 40px;

  @include lt-md {
    padding: 0px;
  }
}

.desc {
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include lt-md {
    padding: 0px;
    text-align: left;
  }
}

.parag {
  line-height: 40px;
  font-weight: 500;

  @include lt-md {
    font-weight: 400;
  }
}

.sub_title {
  color: #002864;
  text-align: justify;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin: 40px 0px;
  padding: 0px 0px 0px 10px;
  border-left: solid 6px var(--blue2);

  @include lt-md {
    font-size: 20px;
  }
}

.fac_img {
  width: 100%;
}

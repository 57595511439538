@use "../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  position: relative;
}

.container {
  padding-top: 150px;
  background-color: #e0edec;

  @include lt-md {
    padding-top: 60px;
  }
}

.fac_container {
  padding-top: 50px;
  background-color: #e0edec;

  @include lt-md {
    padding-top: 60px;
  }
}

.container2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  background-color: #e0edec;
  position: relative;
}

.links {
  width: 25%;
  padding: 50px 5%;
  transition: all 0.2s;

  @include lt-md {
    width: 50%;
    padding: 0px;
    position: fixed;
    z-index: 9999;
    transform: translateY(-50%) translateX(-105%);
    top: 50%;
    box-shadow: 0px 2px 20px rgba($color: #000000, $alpha: 0.3);
  }

  .link {
    width: 100%;
    padding: 40px 20px;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: #0aafda;
    cursor: pointer;
    // border-top: solid 1px rgba(0, 0, 0, 0.114);
    color: white;
    transition: all 0.2s;

    @include lt-md {
      padding: 30px 20px;
      font-size: 17px;
    }
  }

  :nth-child(1) {
    div {
      background-color: #0aa5cf;
    }
  }

  :nth-child(2) {
    div {
      background-color: #0aaedb;
    }
  }

  :nth-child(3) {
    div {
      background-color: rgba(10, 174, 219, 0.9);
      @include lt-md {
        background-color: rgba(10, 174, 219, 1);
      }
    }
  }

  :nth-child(4) {
    div {
      background-color: rgba(10, 174, 219, 0.8);
      @include lt-md {
        background-color: rgba(10, 174, 219, 1);
      }
    }
  }

  :nth-child(5) {
    div {
      background-color: rgba(10, 174, 219, 0.7);
      @include lt-md {
        background-color: rgba(10, 174, 219, 1);
      }
    }
  }
  :nth-child(6) {
    div {
      background-color: rgba(10, 174, 219, 0.6);
      @include lt-md {
        background-color: rgba(10, 174, 219, 1);
      }
    }
  }
  :nth-child(7) {
    div {
      background-color: rgba(10, 174, 219, 0.5);
      @include lt-md {
        background-color: rgba(10, 174, 219, 1);
      }
    }
  }

  .active {
    background-color: white !important;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);

    font-weight: 600;
    color: #002864;
  }
}

.opener {
  display: none;

  height: 120px;
  width: 25px;
  background-color: rgba(10, 174, 219, 1);
  // background-color: red;
  position: fixed;
  z-index: 9999;

  transform: translateY(-50%) translateX(0vw);
  top: 50%;

  border-radius: 0px 500px 500px 0px;
  transition: all 0.2s;

  align-items: center;
  justify-content: center;

  img {
    width: 60%;
    transform: scaleX(-1);
  }

  @include lt-md {
    display: flex;
  }
}

.opened {
  transform: translateY(-50%) translateX(0%);
}

.opener_opened {
  transform: translateY(-50%) translateX(50vw);
  img {
    transform: scaleX(1);
  }
}

.body {
  width: 75%;
  padding: 0px 5% 0px 0px;

  @include lt-md {
    width: 100%;
    padding: 0px;
  }

  .page {
    width: 100%;

    background-color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
}

.full {
  .links {
    display: none;
  }

  .body {
    width: 85%;
    margin: auto;
    padding: 0px 0px 0px 0px;

    @include lt-md {
      width: 100%;
    }
  }
}

.nothing {
  background-color: #e0edec;
  height: 100vh;
  width: 100%;
}

@use "../../../../assets/scss/media-queries.scss" as *;

.body {
  width: 100%;

  .container {
    width: 100%;
    height: 100%;
    position: relative;

    padding: 40px 70px;

    @include lt-xxl {
      padding: 30px 60px;
    }

    @include lt-md {
      padding: 15px 40px;
    }
  }

  .title {
    font-weight: 500;
    margin: 30px 0px 30px 0px;

    @include lt-xxl {
      margin: 25px 0px 25px 0px;
    }
  }
}

.teachers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include lt-md {
    justify-content: space-between;
  }

  .teacher-container {
    width: 23%;
    margin: 20px 2% 20px 0px;
    cursor: pointer;
    transition: all 0.2s;

    @include lt-md {
      width: 48%;
      margin: 20px 0px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .cards-5 {
    width: 19% !important;
    margin: 20px 1% 20px 0px;

    @include lt-md {
      width: 48% !important;
      margin: 20px 0px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .teacher {
    width: 100%;
  }

  .transparent {
    opacity: 0;
  }
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 50px 0px 0px 0px;
  width: 100%;

  @include lt-md {
    margin: 20px 0px 20px 0px;
    justify-content: center;
  }

  button {
    color: #fff;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    padding: 20px 80px;

    @include lt-xxl {
      font-size: 20px;
      padding: 18px 60px;
    }

    @include lt-md {
      font-size: 16px;
      padding: 16px 40px;
    }
  }
}

@use "../../assets/scss/media-queries.scss" as *;

.main {
  padding: 80px 0px 50px 0px;

  @include lt-xxl {
    padding: 70px 0px 50px 0px;
  }

  @include lt-md {
    padding: 0px 0px 0px 0px;
  }
}

.cards-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-llg {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  @include lt-sm {
    flex-direction: column;
  }

  .cards-cont {
    width: 30%;

    @include lt-llg {
      width: 48% !important;
      margin: 10px 0px;
    }

    @include lt-md {
      width: 100% !important;
      margin: 10px 0px;
    }

    @include lt-sm {
      width: 100% !important;
      margin: 20px 0px;
    }
  }
}

.choices {
  padding: 40px 0px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include lt-md {
    padding: 20px 0px;
    flex-wrap: wrap;
  }

  h1 {
    margin: 0px 30px 0px 0px;

    @include lt-md {
      font-size: 17px !important;
      margin: 10px 10px 0px 0px;
    }
  }
}

.pres-sec {
  .descript {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 2;
    text-align: justify;

    color: #000000;

    @include lt-xxl {
      font-size: 22px;
    }

    @include lt-md {
      font-size: 18px;
    }
  }

  .title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.5;
    margin: 0px 0px 20px 0px;
    text-align: justify;

    color: #000000;

    @include lt-md {
      font-size: 20px;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.5;
    margin: 0px;
    text-align: justify;

    color: #000000;

    @include lt-xxl {
      font-size: 24px;
    }

    @include lt-md {
      font-size: 18px;
    }
  }

  p {
    margin: 20px 0px;
    padding: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 1.2;
    /* or 162% */

    text-align: justify;

    color: #000000;

    @include lt-xxl {
      font-size: 22px;
    }

    @include lt-md {
      font-size: 18px;
      font-weight: 400;
    }
  }

  ul {
    font-family: "Montserrat";

    font-weight: 400;
    font-size: 26px;
    /* or 162% */

    text-align: justify;

    color: #000000;

    @include lt-xxl {
      font-size: 22px;
    }

    @include lt-md {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      padding: 0px 0px 0px 12px;
    }
  }
}

.cert-cont {
  img {
    width: 100%;
  }

  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.5;
    /* or 162% */

    text-align: justify;

    color: #000000;

    @include lt-xxl {
      font-size: 24px;
    }

    @include lt-md {
      font-size: 18px;
    }
  }

  p {
    margin: 0px 0px 50px 0px;
    padding: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 2;
    /* or 162% */

    text-align: justify;

    color: #000000;

    @include lt-xxl {
      font-size: 22px;
    }

    @include lt-md {
      font-size: 18px;
      font-weight: 400;
      margin: 0px 0px 30px 0px;
    }
  }
}

.degrees {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  flex-wrap: wrap;
}

.degree {
  width: 45%;
  padding: 40px 0px;

  @include lt-md {
    width: 100%;
    padding: 20px 0px;
  }
}

.Curriculum {
  .head {
    background-color: var(--blue2);
    color: white;
    margin: 0px 0px 0px 0px;
    padding: 15px 10px;
    text-align: center;
    font-size: 22px;

    @include lt-xxl {
      font-size: 22px;
    }

    @include lt-md {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .sub-head {
    background-color: var(--sub-blue2);
    color: white;
    margin: 0px 0px 0px 0px;
    padding: 15px 10px;
    text-align: center;

    @include lt-xxl {
      font-size: 22px;
    }

    @include lt-md {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .head2 {
    background-color: #01376d;
    color: white;
    margin: 0px 0px 0px 0px;
    padding: 15px 10px;

    @include lt-xxl {
      font-size: 22px;
    }

    @include lt-md {
      font-size: 20px;
      font-weight: 600;
    }
  }

  table {
    width: 100%;
    border-spacing: 0px;
    margin: 0px 0px 60px 0px;

    @include lt-xxl {
      margin: 0px 0px 40px 0px;
    }

    td {
      padding: 20px 10px;
      font-size: 20px;
      width: 50%;

      @include lt-xxl {
        font-size: 18px;
      }

      @include lt-md {
        font-size: 18px;
        width: 100%;
        padding: 20px 8px;
      }
    }

    tr:nth-child(odd) {
      background-color: #f3f3f3;
    }
  }
}

// ########################################################################################
// ########################################################################################
// #################################### ALTERNACE  ########################################
// ########################################################################################
// ########################################################################################

.main_alt {
  padding: 80px 5% 50px 5%;

  @include lt-xxl {
    padding: 70px 7% 50px 7%;
  }

  @include lt-md {
    padding: 20px 5% 20px 5%;
  }

  .cards-infos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5%;

    @include lt-md {
      flex-direction: column;
    }

    .cards-cont {
      width: 32%;
      margin: 1% 0.6%;

      @include lt-md {
        width: 100%;
        margin: 20px 0px;
      }
    }
  }
}

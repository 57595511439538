@use "../../../../assets/scss/media-queries.scss" as *;

.main {
  min-height: 100vh;
  width: 100%;
  padding: 60px 150px 0px 150px;

  @include lt-md {
    padding: 40px 20px 0px 20px;
  }
}

.container {
  width: 100%;

  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include lt-md {
    flex-direction: column;
  }
}

.image {
  width: 43%;

  @include lt-md {
    width: 100%;
  }
}

.description {
  width: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include lt-md {
    padding: 50px 0px 0px 0px;
    width: 100%;
  }
}

.content {
  width: 100%;
  position: relative;

  img {
    position: absolute;
    top: -60px;
    left: 0px;
    transform: rotate(180deg);

    @include lt-md {
      width: 80px;
      top: -30px;
    }
  }

  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 95px;
    line-height: 110px;
    margin: 0px;
    padding: 0px;
    text-align: right;
    color: var(--blue2);

    @include lt-xxl {
      font-size: 70px;
    }

    @include lt-md {
      font-size: 30px;
      line-height: 1;
      text-align: center;
    }
  }
}

.see-more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 30px 0px 0px 0px;
}

.pc {
  display: block;
  @include lt-md {
    display: none;
  }
}

.mobile {
  display: none;
  @include lt-md {
    display: block;
  }
}

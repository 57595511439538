@use "../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
}

.container {
  width: 100%;
  padding: 70px 50px 100px 50px;

  @include lt-md {
    padding: 30px 20px 20px 20px;
  }
}

.form {
  width: 90%;
  padding: 50px 0px 20px 0px;
  margin: auto;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @include lt-md {
    width: 100%;
    padding: 40px 0px 20px 0px;
  }

  .w100 {
    width: 100%;
    margin: 0px 0px 15px 0px;
  }

  .w50 {
    width: 48%;
    margin: 0px 0px 15px 0px;

    @include lt-md {
      width: 100%;
    }
  }

  label {
    display: block;
    width: 100%;

    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 200% */

    @include lt-md {
      font-size: 18px;
      line-height: 30px;
    }
  }

  select {
    display: block;
    width: 100%;

    padding: 20px 20px;

    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 200% */
    border: solid 1px #000;

    @include lt-md {
      padding: 15px 15px;
    }
  }

  input,
  textarea {
    display: block;
    width: 100%;

    padding: 6px 20px;
    border: solid 1px #000;

    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 200% */

    @include lt-md {
      padding: 2px 15px;
    }
  }
}

.send {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;

  @include lt-md {
    margin: 0px 0px 20px 0px;
  }

  button {
    padding: 30px 100px;
    width: 90%;
    font-size: 22px;

    @include lt-md {
      width: 100%;
      border-radius: 0px;
      font-size: 20px;
      padding: 25px 0px;
    }
  }
}

.succ {
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5; /* 200% */

  padding: 40px 0px;

  p {
    margin: 20px 0px;

    a {
      color: var(--blue2);
      text-decoration: underline;
    }
  }

  @include lt-md {
    padding: 20px 0px;
    font-size: 20px;
    line-height: 2;
    text-align: left;
  }
}

@use "../../../assets/scss/media-queries.scss" as *;

.main {
  width: 100%;
  height: 100vh;

  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;

  @include lt-md {
    height: 50vh;
  }

  @include lt-sm {
    height: 40vh;
  }
}

.filter2 {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1;
  top: 0;

  background: linear-gradient(to bottom, rgba(#109374, 1), rgba(#109374, 0));
  mix-blend-mode: hard-light;
  opacity: 0.4;

  @include lt-md {
    height: 50vh;
    opacity: 0;
  }

  @include lt-sm {
    height: 40vh;
    opacity: 0;
  }
}

.video-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-md {
    height: 50vh;
  }

  @include lt-sm {
    height: 40vh;
  }

  .video {
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.filter1 {
  width: 100%;
  height: 100vh;

  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0);
  position: absolute;
  z-index: 1;
  top: 0;
  opacity: 0.4;

  @include lt-md {
    height: 40vh;
    opacity: 0;
  }

  @include lt-sm {
    height: 40vh;
    opacity: 0;
  }

  .left {
    width: 75%;
  }

  .right {
    width: 25%;
    position: relative;
    cursor: pointer;
    img {
      width: 100px;
    }
  }
}

.content {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 4;
  top: 0;

  h1 {
    position: absolute;
    color: white;
    font-size: 40px;
    margin-bottom: 10vh;
  }
}
